import React from "react";
import Header from "../components/header";
import { graphql } from "gatsby";
import "../styles/styles.css";
import ReactHtmlParser from "react-html-parser";

const CircusOfTheater = ({ data, pageContext }) => {
  return (
    <div>
      <Header back="true" taal={pageContext.langKey} />
      <div>
        <div className="textContent">
          {ReactHtmlParser(data.allMysqlPages.edges[0].node.content_fr)}
        </div>
      </div>
    </div>
  );
};
export default CircusOfTheater;
export const query = graphql`
  query {
    allMysqlPages(filter: { id: { regex: "/5/" } }) {
      edges {
        node {
          id
          label
          content_fr
        }
      }
    }
  }
`;
